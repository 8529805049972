<template>
  <Page title="Manage assembly">
    <save-bar :loading="loading" :isDirty="isDirty" @cancel="cancelClick" @save="saveClick" />
    <assembly-body
      @is-dirty="(b) => (isDirty = b)"
      @loading="loading = 1"
      @done="loading = 0"
      ref="body"
    />
  </Page>
</template>

<script>
import SaveBar from './subnav/SaveBar.vue'
import AssemblyBody from '../bodies/Assembly.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  data() {
    return {
      loading: 0,
      isDirty: 0
    }
  },
  methods: {
    getChanges() {},
    cancelClick() {
      this.$refs.body.reset()
    },
    saveClick() {
      this.$refs.body.save()
    }
  },
  components: { AssemblyBody, SaveBar }
}
</script>
